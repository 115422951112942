* {
  margin: 0;
  padding: 0;
}

.logincontainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f5f5;
}

.loginform {
  width: 50%;
  height: 70%;
  background-color: #ffffff;
  box-shadow: 15px 15px 15px 15px #f8f5f5;
  display: flex;
  flex-direction: row;
}

.sideimage {
  width: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.inputtxt {
  padding: 10px;
  font-size: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  width: 70%;
  margin-top: 15px;
  border: 1px solid white;
  outline: transparent;
  text-align: center;
  margin-bottom: 15px;
}

.btn {
  padding: 10px;
  font-size: 18px;
  background-color: #0051DE;
  border-radius: 10px;
  width: 70%;
  margin-top: 20px;
  border: 1px solid white;
  outline: transparent;
  text-align: center;
  margin-bottom: 15px;
  color: #ffffff;
}

.btn:hover {
  cursor: pointer;
  background-color: #ffffff;
  color: #0051DE;
}

.btn1 {
  padding: 10px;
  font-size: 16px;
  background-color: #0051DE;
  border-radius: 10px;
  min-width: 60%;
  margin-top: 30px;
  border: 1px solid white;
  outline: transparent;
  text-align: center;
  margin-bottom: 15px;
  color: #ffffff;
  border: 1px solid #0051DE;
}

.btn1:hover {
  cursor: pointer;
  background-color: #cecaca;
  color: #0051DE;
}

.rightdiv {
  width: 50%;
}

.navigationbar {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(180, 113, 4, 0.768627451);
  color: #ffffff;
}

.navimg {
  width: 8%;
}

.navhead {
  width: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bodybar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sidebar {
  width: 15%;
  height: calc(100vh - 50px);
  overflow: auto;
  background-color: #fafafa;
  box-shadow: 1px 0px 1px 1px #fafafa;
}

.sidebardiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebardiv a {
  text-decoration: none;
}

.dashboarduser {
  width: 100%;
  height: calc(100vh - 50px);
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.logoimg {
  width: 80%;
  margin-top: 20px;
}

.sidebarmenu {
  width: 100%;
  margin-top: 50px;
}

.menuitem {
  padding-left: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
}
.menuitem h4 {
  padding: 10px;
}

.menuitem:hover {
  background-color: #f0ecec;
}

.menuitemlogout:hover {
  cursor: pointer;
}

.icon {
  padding: 5px;
  width: 20px;
  height: 20px;
}

.rightbar {
  width: 85%;
}

.contentAction {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  width: 50%;
  padding-left: 5vw;
}
.contentAction h2 {
  padding: 20px;
  color: #0051DE;
  text-decoration: underline;
}

.forminput {
  padding: 1%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  justify-content: space-around;
}
.forminput label {
  font-weight: bold;
  width: 92%;
}
.forminput label:last-child {
  color: darkblue;
  font-weight: 600;
}
.forminput p {
  color: darkblue;
  font-weight: 600;
  text-align: justify;
  width: 92%;
  padding-top: 3px;
}

.inputtxt1 {
  border: 1px solid #0051DE;
  outline: transparent;
  padding: 6px;
  width: 90%;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 12PX;
}
.inputtxt1 option {
  color: #0051DE;
}

.inputtxt1:focus {
  outline: 1px solid rgb(133, 255, 2);
}

.inputtxt:focus {
  outline: 1px solid rgb(110, 247, 31);
}

.inputtxt1:disabled {
  border: transparent;
  font-weight: bold;
  outline: 1px solid rgba(177, 176, 176, 0.795);
}

.checkinput {
  width: 30%;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.checkinput label {
  width: auto;
  color: black;
  font-weight: lighter;
}
.checkinput label:first-child {
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.leftdivlogin {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightdivlogin {
  height: 100%;
  background-color: rgba(180, 113, 4, 0.768627451);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
}

.previousforms {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previousformsheading {
  display: flex;
  flex-direction: row;
  width: 98%;
  margin-left: 2%;
}
.previousformsheading div {
  border: 1px solid grey;
}
.previousformsheading div p {
  padding: 5px;
  text-align: center;
  font-size: medium;
  overflow: auto;
  min-width: 35%;
}
.previousformsheading div:first-child {
  width: 60%;
}
.previousformsheading div:last-child {
  width: 36%;
}

.previousformsheading:first-child div p {
  text-align: center;
  font-weight: bold;
}

.btntbl {
  min-width: 50%;
  text-align: center;
  background-color: rgba(180, 113, 4, 0.768627451);
  color: #fff;
  border: 1px solid rgba(180, 113, 4, 0.768627451);
  padding: 10px 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.btntbl:hover {
  cursor: pointer;
  opacity: 0.7;
}

.hideforminput {
  display: flex;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(190, 180, 180, 0.281);
  display: flex;
  justify-content: center;
  align-items: center;
}

.outerscreen {
  width: 30vw;
  height: 40vh;
}
.outerscreen .innerscreen {
  width: 100%;
  background-color: #fff;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.outerscreen .innerscreen h4 {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: rgba(119, 74, 2, 0.9843137255);
}
.outerscreen .innerscreen .modalinput {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.outerscreen .innerscreen .modalinput input {
  padding: 7px;
  border-radius: 5px;
  outline: transparent;
  width: 60%;
  border: 1px solid #0051DE;
}
.outerscreen .innerscreen .modalinput input:focus {
  outline: 2px solid rgba(180, 113, 4, 0.768627451);
}

.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(190, 180, 180, 0.281);
  display: flex;
  justify-content: center;
  align-items: center;
}

.outerscreen1 {
  width: 50vw;
  height: 40vh;
}
.outerscreen1 .innerscreen {
  width: 100%;
  background-color: #fff;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.outerscreen1 .innerscreen h4 {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: rgba(119, 74, 2, 0.9843137255);
}
.outerscreen1 .innerscreen .modalinput {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.outerscreen1 .innerscreen .modalinput input {
  padding: 7px;
  border-radius: 5px;
  outline: transparent;
  width: 60%;
  border: 1px solid #0051DE;
}
.outerscreen1 .innerscreen .modalinput input:focus {
  outline: 2px solid rgba(180, 113, 4, 0.768627451);
}

.btn2 {
  background-color: rgba(180, 113, 4, 0.768627451);
  border: 1px solid rgba(180, 113, 4, 0.768627451);
  width: 40%;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
  margin-left: 10px;
}

.btn2:hover {
  color: white;
  background-color: rgba(180, 113, 4, 0.768627451);
  opacity: 0.7;
  cursor: pointer;
}

.btn3 {
  color: brown;
  border: transparent;
  background-color: transparent;
  margin-right: 10px;
}

.btn3:hover {
  opacity: 0.7;
  cursor: pointer;
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(199, 195, 195, 0.671);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading .loadingcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading .loadingcontainer p {
  color: #b97200;
  font-weight: bold;
}

.spinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-top: 5px solid #b97200;
  -webkit-animation: spinner 2s linear infinite;
          animation: spinner 2s linear infinite;
  margin-bottom: 10px;
}

.snack {
  position: fixed;
  bottom: 5%;
  width: 40%;
  left: 30%;
}

.snackclose {
  text-align: right;
  margin-bottom: -1px;
  color: brown;
  font-weight: bold;
  margin-right: -5px;
}

.snackclose:hover {
  cursor: pointer;
  opacity: 0.8;
}

.snackinner .snackmsg {
  background-color: #b97200;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  max-height: 100px;
  overflow: auto;
}

.snackprogress {
  background-color: brown;
}

.usrtbl {
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  overflow: auto;
}
.usrtbl table {
  min-width: 60%;
  border-collapse: collapse;
}
.usrtbl table th {
  background-color: lightgray;
}
.usrtbl table td, .usrtbl table th {
  border: 1px solid green;
  padding: 7px;
  text-align: justify;
}
.usrtbl table td .btn2, .usrtbl table th .btn2 {
  width: 80%;
}

.footer {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer p {
  color: #b97200;
  font-size: smaller;
  text-decoration: underline;
}

@-webkit-keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 0) and (max-width: 720px) {
  .loginform {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .leftdiv {
    display: none;
  }
  .rightdiv {
    width: 100%;
  }
  .leftdivlogin {
    width: 100%;
    height: 100px;
    display: flex;
  }
  .leftdivlogin img {
    width: 200px;
  }
  .forminput {
    width: 98%;
  }
  .sidebar {
    width: 0%;
  }
  .rightbar {
    width: 100%;
  }
  .checkinput {
    width: 50%;
  }
  .btntbl {
    min-width: 80%;
  }
  .outerscreen {
    width: 85vw;
    height: 40vh;
  }
  .outerscreen1 {
    width: 95vw;
    height: 40vh;
  }
  .snack {
    width: 80%;
    left: 10%;
  }
  .usrtbl {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .usrtbl table {
    width: 100%;
  }
}
@media screen and (min-width: 721px) and (max-width: 1280px) {
  .loginform {
    width: 80%;
    height: 100%;
  }
  .navimg {
    width: 0%;
  }
  .navhead {
    width: 100%;
  }
  .forminput {
    width: 48%;
  }
  .hideforminput {
    display: none;
  }
  .previousformsheading div:first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .outerscreen {
    width: 50vw;
    height: 40vh;
  }
  .outerscreen1 {
    width: 70vw;
    height: 40vh;
  }
  .snack {
    width: 60%;
    left: 20%;
  }
  .usrtbl {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .usrtbl table {
    min-width: 70%;
  }
}
.workdonedecision:hover {
  background-color: #fff7e9;
}

@media screen and (min-width: 1281px) {
  .navimg {
    width: 0%;
  }
  .navhead {
    width: 100%;
  }
  .forminput {
    width: 31%;
  }
  .btn1 {
    min-width: 30%;
  }
  .previousformsheading div:first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}/*# sourceMappingURL=App.css.map */